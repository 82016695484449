// @flow

import React from 'react';
import { observer } from 'mobx-react';
import { graphql, StaticQuery } from 'gatsby';
import filter from 'lodash/filter';
import { computed } from 'mobx';

import JsonStore from '../../stores/json-store';
import styles from './style.module.scss';
// import BlurUpImage from "../BlurUpImage";
import Divider from "../Divider";
import Button from "../Button";
import UiStore from '../../stores/ui-store';

type HeaderProps = {
  pathname: string,
  json: JsonStore,
  ui: UiStore,
}

const HEADER_HEIGHT: number = 395;

@observer
class Header extends React.Component<HeaderProps, any> {

  @computed get bgPosition(): number {
    if (this.props.ui.windowWidth < 1000) return 0;
    if (this.props.ui.windowScrollY <= 0) return 0;
    else if (this.props.ui.windowScrollY >= HEADER_HEIGHT) return HEADER_HEIGHT / 4;
    else return (this.props.ui.windowScrollY / 4);
  }

  render() {

    const bgPosition = this.bgPosition;
    const paths = this.props.pathname.split('/');
    let pathnameNoSlash;
    if (paths[1] === 'updates') pathnameNoSlash = 'updates';
    else pathnameNoSlash = paths.length >= 3 && paths[2] ? `${paths[1]}/${paths[2]}` : paths[1];

    return (
      <StaticQuery
        query={graphql`
            query {
            newsletter: allContentfulNewsletter(filter: {contentful_id: {eq: "1OiCIQr6rui6C8Aeu80QC6"}}) {
              edges {
                node {
                  showSubHeaderBlock
                  url
                  headerText
                }
              }
            }
              search: allContentfulSearch(filter: {contentful_id: {eq: "3XEX5ueCw0eWQosesU8EcG"}}) {
                edges {
                  node {
                    url
                    metadata {
                      title
                    }
                  }
                }
              }
              updates: allContentfulUpdates(filter: {contentful_id: {eq: "6idBb13bFeSWQMm2y8WqOk"}}) {
                edges {
                  node {
                    showSubHeaderBlock
                    url
                    headerText
                  }
                }
              }
              gallery: allContentfulGallery(filter: {contentful_id: {eq: "1Vkn2fCAXOkUcgKC2Uwyk8"}}) {
                edges {
                  node {
                    showSubHeaderBlock
                    url
                    headerText
                    headerImage {
                      title
                      file {
                        url
                      }
                    }
                  }
                }
              }
              pages: allContentfulPage(filter:{
                  url:{ne:null}
                }) {
                  edges {
                    node {
                      showSubHeaderBlock
                      url
                      headerText
                      headerImage {
                      title
                        file {
                          url
                        }
                      }
                    }
                  }
                }
              galleryPages: allContentfulGalleryListPage(filter: {url: {ne: null}}) {
                edges {
                  node {
                    url
                    showSubHeaderBlock
                    url
                    headerText
                    headerImage {
                      title
                      file {
                        url
                      }
                    }
                  }
                }
              }
              ui: contentfulUi(name: {eq: "UI"}) {
                headerBackground {
                  file {
                    url
                  }
                }
                headerBackgroundSmall {
                  file {
                    url
                  }
                }
                headerText
                headerTextAbove
                accountLogin {
                  url
                  externalUrl
                  type
                  display
                }
              }
            }
          `}
        render={data => {
          const pagesData = data.pages.edges;
          const galleryData = data.gallery.edges;
          // const spotlightData = data.spotlight.edges;
          const updatesData = data.updates.edges;
          const newsletterData = data.newsletter.edges;
          const searchData = data.search.edges;
          const galleryPages = data.galleryPages.edges;
          let pageData: any;
          try {
            pageData = filter(pagesData, {node: {url: pathnameNoSlash}})[0];
            if (!pageData) pageData = filter(galleryData, {node: {url: pathnameNoSlash}})[0];
            // if (!pageData) pageData = filter(spotlightData, {node: {url: pathnameNoSlash}})[0];
            if (!pageData) pageData = filter(updatesData, {node: {url: pathnameNoSlash}})[0];
            if (!pageData) pageData = filter(newsletterData, {node: {url: pathnameNoSlash}})[0];
            if (!pageData) pageData = filter(searchData, {node: {url: pathnameNoSlash}})[0];
            if (!pageData) pageData = filter(galleryPages, {node: {url: pathnameNoSlash}})[0];
            if (!pageData) pageData = filter(galleryPages, {node: {url: paths[1]}})[0];
          } catch(e) {
            console.error(e);
          }
          if (pageData) return (
            <>
              <div className={styles.container}>
                {/* <div className={styles.bg}>
                  <BlurUpImage src={`https://images.ctfassets.net/nec7dfptn2hs/O8NZdruvKTf2mxwoRKzvz/c11a834123a85eedec018102d2dadf11/top-bg.jpg`} />
                  <div className={styles.pattern}>

                  </div>
                  <div className={styles.overlay}>

                  </div>
                </div>
                <div className={styles.images}>
                  <div className={styles.imagesInner}
                       style={{transform: `translateY(${bgPosition}px)`}}>
                    <div className={styles.embroideryLeft}>
                      <BlurUpImage src={`https://images.ctfassets.net/nec7dfptn2hs/aLvulIgI6Zp15KmRtvg36/6b11597807f8c3fd74d98aad89846a84/embroidery-page.png`} />
                    </div>
                    <div className={styles.left}>
                      <BlurUpImage src={`https://images.ctfassets.net/hlo9sa4bpxwo/4ulPEkWPTGGRYjJtR2QVas/ed97c4830c2a6eaac00415946894f5d5/page-header-left.png`} />
                    </div>
                    <div className={styles.right}>
                      <BlurUpImage src={`https://images.ctfassets.net/hlo9sa4bpxwo/1U5IFqqp3FFtZppExWpqnI/18b98e5e099b62eed01b011117b980d2/page-header-right.png`} />
                    </div>
                    <div className={styles.embroideryRight}>
                      <BlurUpImage src={`https://images.ctfassets.net/nec7dfptn2hs/aLvulIgI6Zp15KmRtvg36/6b11597807f8c3fd74d98aad89846a84/embroidery-page.png`} />
                    </div>
                  </div>
                </div> */}
                <div className={styles.text}
                     style={{transform: `translateY(${bgPosition}px)`}}>
                  {pageData.node.headerText && <h1>{pageData.node.headerText}</h1>}
                </div>
              </div>
              {false && <div className={styles.subHeader}>
                <p>
                  {data.ui.headerTextAbove}
                </p>
                <h4>
                  {data.ui.headerText}
                </h4>
                <div className={styles.divider}>
                  <Divider color={'#ff6d64'}/>
                </div>
                {data.ui.showButton && <div className={styles.button}>
                  <Button type={data.ui.accountLogin.type}
                          url={data.ui.accountLogin.url}
                          external={data.ui.accountLogin.externalUrl}
                          display={data.ui.accountLogin.display}/>
                </div>}
              </div>}
            </>
          );
          return (
            <>
            </>
          )
        }}
      />
    )
  }
}

export default Header;
