// @flow

import React from 'react';

import styles from './style.module.scss';

type DividerProps = {
  color: string,
  dot?: boolean,
}

class Divider extends React.PureComponent<DividerProps, any> {

  render() {

    return (
      <div className={styles.container}>
        {this.props.dot && <div className={styles.dot}
                                style={{background: this.props.color}}>

        </div>}
        <div className={styles.line}
             style={{background: this.props.color}}>

        </div>
        {this.props.dot && <div className={styles.dot}
                                style={{background: this.props.color}}>

        </div>}
      </div>
    )
  }
}

export default Divider;
