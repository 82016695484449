// @flow

import React from 'react';
import { observer, inject } from 'mobx-react';
import ReactResizeDetector from 'react-resize-detector';

import { STORE_UI, STORE_JSON, STORE_UPDATES, STORE_OVERLAY } from '../constants/stores';
import Nav from '../components/Nav';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Overlay from '../components/Overlay';
import Snackbar from '../components/Snackbar';
import Tiles from '../components/Tiles';
import Updates from '../components/Updates';
import UiStore from '../stores/ui-store';
import UpdatesStore from '../stores/updates-store';
import JsonStore from '../stores/json-store';
import OverlayStore from '../stores/overlay-store';
import PosterLightbox from "../components/Parallax/SceneOne/Lightbox";
import styles from './layout.module.scss';

type LayoutProps = {
    ui: UiStore,
    json: JsonStore,
    updates: UpdatesStore,
    overlay: OverlayStore,
    children: any,
    location: {
        pathname: string
    },
}

@inject(STORE_UI, STORE_JSON, STORE_UPDATES, STORE_OVERLAY)
@observer
class Layout extends React.Component < LayoutProps, any > {

        handleMouseEnter = () => {
            this.props[STORE_UI].setNavExpanded(false);
        };

        handleResize = (width: number) => {
            if (width !== 0) this.props[STORE_UI].setWindowWidth(width);
        };

        handleHeightResize = (width: number, height: number) => {
            if (height !== 0) this.props[STORE_UI].setWindowHeight(height);
        };

        click() {
            this.props[STORE_UI].setSnackbarShowing(false);
        }

        componentDidMount() {
            if (window) {
                setTimeout(() => {
                    //quantummetric no longer in use
                    //(function() {
                    //  var qtm = document.createElement('script'); qtm.type = 'text/javascript'; qtm.async = 1;
                    //  qtm.src = 'https://cdn.quantummetric.com/qscripts/quantum-aegfestivals.js';
                    //  var d = document.getElementsByTagName('script')[0]; !window.QuantumMetricAPI && d.parentNode.insertBefore(qtm, d);
                    // })();

                    // Snapchat no longer in use
                    // (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
                    // {a.handleRequest?
                    //  a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
                    //  a.queue=[];var s='script';var r=t.createElement(s);r.async=!0;
                    //  r.src=n;var u=t.getElementsByTagName(s)[0];
                    //  u.parentNode.insertBefore(r,u);})(window,document,
                    //  'https://sc-static.net/scevent.min.js');
                    // window.snaptr('init', '531a90a3-ba42-4ff0-92e0-3799d607707d');
                    // window.snaptr('track', 'PURCHASE');

                    // Tiktok no longer in use
                    // (function() {
                    //  var ta = document.createElement('script'); ta.type = 'text/javascript'; ta.async = true;
                    //  ta.src = document.location.protocol + '//' + 'static.bytedance.com/pixel/sdk.js?sdkid=BMIG5HP5IEM1Q8BOKLF0';
                    //  var s = document.getElementsByTagName('script')[0];
                    //  s.parentNode.insertBefore(ta, s);
                    // })();

                    // Google Analytics older method
                    // (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                    //  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                    //  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                    // })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
                    // ga('create', 'UA-19534992-46', 'auto');
                    // ga('send', 'pageview');

                    // Universal Pixel
                    ! function(e, t, n, a, i, l, o, s, r) { e[i] || (o = e[i] = function() { o.process ? o.process.apply(o, arguments) : o.queue.push(arguments) }, o.queue = [], o.t = 1 * new Date, s = t.createElement(n), s.async = 1, s.src = a + "?t=" + Math.ceil(new Date / l) * l, r = t.getElementsByTagName(n)[0], r.parentNode.insertBefore(s, r)) }(window, document, "script", "//tracking.aegpresents.com/universalpixel/universalpixel.js", "kl", 864e5), kl("event", "pageload");

                    // Google Analytics
                    (function() {
                        const gaScript = document.createElement('script');
                        const inlineScript = document.createElement('script');
                        gaScript.type = 'text/javascript';
                        gaScript.async = 1;
                        gaScript.src = 'https://www.googletagmanager.com/gtag/js?id=UA-19534992-46';
                        inlineScript.innerHTML = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'UA-19534992-46');`;
                        document.getElementsByTagName('script')[0].parentNode.insertBefore(gaScript, document.getElementsByTagName('script')[0]);
                        document.getElementsByTagName('script')[0].parentNode.insertBefore(inlineScript, document.getElementsByTagName('script')[0]);
                    })();
                }, 300);
                // setTimeout(() => {
                //   (function (a, b) {var c = [], d = function () {for (var b = arguments.length, c = Array(b), d = 0; d < b; d++) c[d] = arguments[d]; return a.spotAds.conv.q.push(c) }, e = "script", f = b.getElementsByTagName(e), g = f[f.length - 1], h = b.createElement(e); h.async = !0, h.id = "spotevents", h.src = "https://pixel-static.spotify.com/conversion.min.js"; try {a.spotAds ? !a.spotAds.conv && (a.spotAds.conv = {q: c, sdk: d }) : a.spotAds = {conv: { q: c, sdk: d } }, g.parentNode.appendChild(h) } catch (a) {} })(window, document);
                //   window.spotAds.conv.sdk('init', '583875063');
                //   window.spotAds.conv.sdk('track', 'PAGE_VIEW');
                // }, 400);
            }
        }

        render() {
            const isLandingPage: boolean = false;
            // const isHome: boolean = this.props.location.pathname === '/';
            const paths = this.props.location.pathname.split('/');
            const pathnameNoSlash = paths.length >= 3 && paths[2] ? `${paths[1]}/${paths[2]}` : paths[1];

            return ( <
                    > {!isLandingPage && < >
                        <
                        PosterLightbox store = { this.props[STORE_OVERLAY] }
                        /> <
                        Overlay overlay = { this.props[STORE_OVERLAY] }
                        ui = { this.props[STORE_UI] }
                        /> <
                        Snackbar ui = { this.props[STORE_UI] }
                        /> <
                        Nav pathname = { this.props.location.pathname }
                        ui = { this.props[STORE_UI] }
                        json = { this.props[STORE_JSON] }
                        /> <
                        />} <
                        div id = { 'body' }
                        onClick = {
                            () => this.click() }
                        onMouseEnter = { this.handleMouseEnter } > {!isLandingPage && < >
                            <
                            div className = { styles.landingWrapper } >
                            <
                            Header
                            pathname = { this.props.location.pathname }
                            ui = { this.props[STORE_UI] }
                            json = { this.props[STORE_JSON] }
                            /> <
                            Tiles pathname = { this.props.location.pathname }
                            location = { this.props.location }
                            ui = { this.props[STORE_UI] }
                            json = { this.props[STORE_JSON] } > { this.props.children } <
                            /Tiles> <
                            /div> <
                            />} {
                                isLandingPage && < > { this.props.children } <
                                    />} <
                                    /div> {
                                        !isLandingPage && (pathnameNoSlash !== 'updates') && < Updates json = { this.props[STORE_JSON] }
                                        isHome = { false }
                                        updates = { this.props[STORE_UPDATES] }
                                        />} {
                                            !isLandingPage && < Footer json = { this.props[STORE_JSON] }
                                            />} <
                                            ReactResizeDetector handleWidth onResize = { this.handleResize }
                                            /> <
                                            div style = {
                                                    {
                                                        position: 'fixed',
                                                        top: 0,
                                                        left: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        zIndex: -100
                                                    }
                                                } >
                                                <
                                                ReactResizeDetector handleHeight onResize = { this.handleHeightResize }
                                            /> <
                                            /div> <
                                            />
                                        )
                                    }
                            }

                            export default Layout;