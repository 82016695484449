// @flow

import './polyfills';
import React from 'react';
import { configure } from 'mobx';
import { Provider } from 'mobx-react';

import './src/styles/normalize.scss';
import './src/styles/global.scss';
import { createStores } from './src/stores/create-store';

import Layout from './src/containers/layout';

configure({ enforceActions: 'always' });

const rootStore = createStores(true);

export const wrapPageElement = ({ element, props }: any) => (
  <Provider {...rootStore}>
    <Layout {...props}>
      {element}
    </Layout>
  </Provider>
);