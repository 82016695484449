// @flow

import React from 'react';
import {observer} from 'mobx-react';
import {observable, action} from 'mobx';

import UiStore from '../../stores/ui-store';
import styles from './style.module.scss';

type SnackbarProps = {
  ui: UiStore
}

@observer
class Snackbar extends React.Component<SnackbarProps, any> {


  @observable loaded: boolean = false;

  @action
  setLoaded = (loaded: boolean): void => {
    this.loaded = loaded;
  };

  render() {

    return (
      <div className={styles.container}
           style={{
             transform: this.props.ui.snackbarShowing ? 'none': 'translateY(500px)'
           }}>
        {this.props.ui.snackbarText}
      </div>
    )
  }
}

export default Snackbar;
