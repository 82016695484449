// @flow

import React from 'react';
import { Link } from 'gatsby';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import { graphql, StaticQuery } from 'gatsby';
import { observer } from 'mobx-react';

import dashToCamelCase from '../../utils/dash-to-camel-case';
import BlurUpImage from '../../components/BlurUpImage';
import styles from './style.module.scss';

type ButtonProps = {
  locked?: boolean,
  type: 'beige'
  | 'blue'
  | 'pink'
  | 'pink-large'
  | 'splash'
  | 'patch-small'
  | 'patch-large'
  | 'patch-large-circle'
  | 'patch-large-square'
  | 'patch-large-square-two'
  | 'image-rectangle'
  | 'frame-one'
  | 'frame-two'
  | 'frame-three',
  url?: string,
  external?: string,
  display: string,
  imageUrl?: string,
  imageUrlSmall?: string,
}

@observer
class Button extends React.Component<ButtonProps, any> {

  render() {

    const LinkButton = props => {
      if (props.url) {
        return (
          <Link to={`/${props.url}`}>
            {props.children}
          </Link>
        )
      } else return (
        <a
          href={props.external}
          target={'_blank'}
          rel="noopener noreferrer"
        >
          {props.children}
        </a>
      )
    };

    return (
      <StaticQuery
        query={graphql`
            query {
              ui: contentfulUi(name: {eq: "UI"}) {
                buttonImages {
                  title
                  file {
                    url
                    details {
                      image {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          `}
        render={data => {
          let patchData: any;
          const isFrame = includes(this.props.type, 'frame');
          if (isFrame) patchData = filter(data.ui.buttonImages, { title: this.props.type })[0];
          return (
            <div className={styles[dashToCamelCase(this.props.type)]}>
              {!isFrame && (
                <LinkButton
                  url={this.props.url || ''}
                  external={this.props.external || ''}>
                  <span dangerouslySetInnerHTML={{
                    __html: this.props.display
                  }}>

                  </span>
                </LinkButton>
              )}
              {isFrame && (
                <div className={styles.ratio}
                  style={{
                    paddingTop: `${patchData.file.details.image.height / patchData.file.details.image.width * 100}%`
                  }}>
                  <div className={styles.wrapper}>
                    <BlurUpImage src={patchData.file.url} />
                    <div className={styles.back}>
                      <BlurUpImage src={this.props.imageUrl || ''} />
                    </div>
                    <div className={styles.front}>

                    </div>
                    <div className={styles.text}>
                      <LinkButton
                        url={this.props.url || ''}
                        external={this.props.external || ''}>
                        <span dangerouslySetInnerHTML={{
                          __html: this.props.display
                        }}>

                        </span>
                      </LinkButton>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )
        }}
      />
    )
  }
}

export default Button;
