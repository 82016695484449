// @flow

import React from 'react';
import { Link } from 'gatsby';
import { observer } from 'mobx-react';
import { graphql, StaticQuery } from 'gatsby';
import filter from 'lodash/filter';
import * as classNames from 'classnames';

import JsonStore from '../../stores/json-store';
import UiStore from '../../stores/ui-store';
import styles from './style.module.scss';
import BlurUpImage from "../BlurUpImage";
import getImageUrl from "../../utils/get-image-url";
import Divider from '../../components/Divider';
import { wrapSymbols } from '../../utils/wrap-symbol';

type TilesProps = {
  location: any,
  pathname: string,
  json: JsonStore,
  ui: UiStore,
  children: any,
}

@observer
class Tiles extends React.Component<TilesProps, any> {

  render() {

    const paths = this.props.pathname.split('/');
    const pathnameNoSlash = paths.length >= 3 && paths[2] ? `${paths[1]}/${paths[2]}` : paths[1];

    return (
      <StaticQuery
        query={graphql`
            query {
              pages: allContentfulPage(filter:{
                navigationTiles:{contentful_id:{ne: null}}
              }) {
                edges {
                  node {
                    url
                    navigationTiles {
                      tiles {
                        externalUrl
                        url
                        display
                        image {
                          file {
                            url
                          }
                        }
                        imageSmall {
                          file {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `}
        render={data => {
          const isHome: boolean = this.props.pathname === '/';
          const pagesData = data.pages.edges;
          let pageData: any;
          try {
            pageData = filter(pagesData, { node: { url: pathnameNoSlash } })[0];
          } catch (e) {
            console.log(e);
          }
          if (pageData && pageData.node) {
            return (
              <div className={styles.outer}>
                {/* <BlurUpImage repeat src={`https://images.ctfassets.net/nec7dfptn2hs/5ZYR1sGjl7kjtk56BDgK69/5e9223bada7e41b0a8ed8736b5dcc41b/paper.jpg`} />
                <div className={styles.bgLeft}>
                  <img src="https://images.ctfassets.net/hlo9sa4bpxwo/2iF6nS2iLNgtSgcrSjVRzp/d6cbdb9d09575aaf410e298ecb59ce68/bg-left.png" />
                </div>
                <div className={styles.bgRight}>
                  <img src="https://images.ctfassets.net/hlo9sa4bpxwo/5uc8KpWnwedtsomOJdgJH1/bfaa912b20fc0f019befa6856055c6d0/bg-right.png" />
                </div> */}
                <div className={styles.container}>
                  <div className={styles.tiles}>
                    {pageData.node.navigationTiles.tiles.map((item, index) => {
                      return (
                        <div key={`${index}Tile`}
                          className={classNames({
                            [styles.tile]: true,
                            [styles.regular]: true,
                            [styles.active]: pathnameNoSlash === item.url
                          })}>
                          <div className={styles.ratio}>
                            <div className={styles.wrapper}>
                              <BlurUpImage src={getImageUrl(item.image)}
                                srcSmall={getImageUrl(item.imageSmall)}
                                borderRadius={true} />
                              <div className={styles.overlay}>
                                <div className={styles.diamond}>
                                  <div className={styles.diamondInner}>

                                  </div>
                                </div>
                              </div>
                              {item.url && <Link to={`/${item.url}#content`}>
                                <span dangerouslySetInnerHTML={{ __html: wrapSymbols(item.display) }} />
                              </Link>}
                              {item.externalUrl && <a href={`${item.externalUrl}`}
                                target={'_blank'} rel="noopener noreferrer">
                                <span dangerouslySetInnerHTML={{ __html: wrapSymbols(item.display) }} />
                              </a>}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div
                    className={styles.children}
                    style={{
                      display: ''
                    }}>
                    {this.props.children}
                  </div>
                  <div className={styles.divider}>
                    <Divider color={'#ff6d64'} />
                  </div>
                </div>
              </div>
            );
          }
          return (
            <>
              {isHome && this.props.children}
              {!isHome && <div className={styles.outer}>
                <div className={styles.container}>
                  {!isHome && <div className={styles.children}>
                    {this.props.children}
                  </div>}
                  {pathnameNoSlash !== 'updates' && <div className={classNames({
                    [styles.dividerNoTiles]: !isHome,
                    [styles.divider]: isHome
                  })}>

                  </div>}
                </div>
              </div>}
            </>
          )
        }}
      />
    )
  }
}

export default Tiles;
