// @flow

import { reaction } from 'mobx';
import React from 'react';
import { observer } from 'mobx-react';

import styles from './style.module.scss';
import OverlayStore from '../../../../stores/overlay-store';

type PosterLightboxProps = {
  store: OverlayStore,
}

@observer
class PosterLightbox extends React.Component<PosterLightboxProps, any> {

  componentDidMount(): void {
    reaction(
      () => this.props.store.posterActive,
      () => {
        if (this.props.store.posterActive) {
          setTimeout(() => {
            window.addEventListener('click', this.handler)
          }, 1);
        } else window.removeEventListener('click', this.handler);
      }
    );
  }

  handler = () => this.props.store.setPosterActive(false);

  handleCloseClick = () => {
    this.props.store.setPosterActive(false);
  };

  render() {

    return (
      <div className={styles.wrapper}
           style={{
             pointerEvents: this.props.store.posterActive ? 'auto' : 'none',
             opacity: this.props.store.posterActive ? 1 : 0,
           }}>
        <div className={styles.close}
             onClick={this.handleCloseClick}>
          x
        </div>
        <div className={styles.imgWrapper}>
          <img onClick={e => e.stopPropagation()} src={this.props.store.posterUrl} />
        </div>
      </div>
    )
  }
}

export default PosterLightbox;
