// @flow

import { STORE_UI, STORE_JSON, STORE_UPDATES, STORE_OVERLAY } from '../constants/stores';
import JsonStore from './json-store'
import UiStore from './ui-store';
import UpdatesStore from './updates-store';
import OverlayStore from './overlay-store';
import forEach from 'lodash/forEach';

export function createStores(initForBrowserUse: boolean=false) {

  const jsonStore = new JsonStore();
  const uiStore = new UiStore();
  const updatesStore = new UpdatesStore(uiStore);
  const overlayStore = new OverlayStore(uiStore);

  return forEach({
    [STORE_JSON]: jsonStore,
    [STORE_UI]: uiStore,
    [STORE_UPDATES]: updatesStore,
    [STORE_OVERLAY]: overlayStore,
  }, (store: any) => {
    if (initForBrowserUse && store.initForBrowserUse) store.initForBrowserUse();
  });
}
