// @flow

import { Link } from 'gatsby';
import React from 'react';
import { observer } from 'mobx-react';
import * as classNames from 'classnames';

import styles from './style.module.scss';
import UiStore from '../../../stores/ui-store';
import Divider from '../../Divider';

// import { JSON_METADATA } from '../constants/json';
type DesktopNavProps = {
  data: any,
  pathname: string,
  ui: UiStore,
}

@observer
class DesktopNav extends React.Component<DesktopNavProps, any> {

  render() {

    const isActive = ({ isCurrent }) => {
      return isCurrent ? { className: styles.active } : null
    };

    const ExactNavLink = props => (
      <Link getProps={isActive} {...props} />
    );

    const wrapperClass = classNames({
      [styles.wrapper]: true,
      [styles.active]: this.props.ui.navExpanded,
    });

    const mobileBgClass = classNames({
      [styles.mobileBg]: true,
      [styles.active]: this.props.ui.navExpanded,
    });


    const bgClass = classNames({
      [styles.bg]: true,
      [styles.active]: this.props.ui.navExpanded,
    });

    return (
      <>
        <div className={mobileBgClass}>

        </div>
        <div className={bgClass}>

        </div>
        <div className={wrapperClass}
          onClick={() => this.props.ui.setNavExpanded(false)}>
          <div className={styles.innerWrapper}>
            {this.props.data.nav && this.props.data.nav.childPages.map((item, index) => {
              return (
                <React.Fragment key={`${index}NavLink`}>
                  <div className={styles.titleLink}>
                    {item.url ? (
                      <ExactNavLink
                        to={`/${item.url}`}>
                        {item.display}
                      </ExactNavLink>
                    ) : (
                      <a
                        href={item.externalUrl}
                        target={'_blank'}
                        rel="noopener noreferrer"
                      >
                        {item.display}
                      </a>
                    )}
                  </div>
                  {item.childPages && item.childPages.map((innerItem, innerIndex) => {
                    return (
                      <div className={styles.childLink}
                        key={`${innerIndex}NavLinkInner`}>
                        {innerItem.url ?
                          <ExactNavLink
                            to={`/${innerItem.url}`}>
                            {innerItem.display}
                          </ExactNavLink> :
                          <a
                            href={innerItem.externalUrl}
                            target={'_blank'}
                            rel="noopener noreferrer"
                          >
                            {innerItem.display}
                          </a>}
                      </div>
                    )
                  })}
                  <div className={styles.divider}>
                    <Divider color={'#ff6d64'} />
                  </div>
                </React.Fragment>
              )
            })}
          </div>
        </div>
      </>
    )
  }
}

export default DesktopNav;
