// @flow

import React from 'react';
import { observer } from 'mobx-react';
import { graphql, StaticQuery } from 'gatsby';

import JsonStore from '../../stores/json-store';
import { JSON_UI } from '../../constants/json';
import styles from './style.module.scss';
import Newsletter from './Newsletter';
import Links from './Links';
import Divider from '../Divider';
import Social from '../Social';

type FooterProps = {
  json: JsonStore,
  splash?: boolean
}

@observer
class Footer extends React.Component<FooterProps, any> {

  componentDidMount() {
    if (process.env.GATSBY_PREVIEW === 'true' && window) {
      this.props.json.getData(JSON_UI, {
        contentType: 'ui',
        fieldEquality: {
          name: 'UI'
        }
      });
    }
  }

  render() {

    const jsonData = this.props.json.store.get(JSON_UI) || null;

    return (
      <StaticQuery
        query={graphql`
            query {
              ui: contentfulUi(name: {eq: "UI"}) {
                footerLinks {
                  url
                  externalUrl
                  display
                }
                footerHeader
                footerDisclaimer
                footerButton {
                  url
                  display
                }
                footerBackgroundSmall {
                  file {
                    url
                  }
                }
                footerBackground {
                  file {
                    url
                  }
                }
              }
            }
          `}
        render={data => (
          <div className={styles.container}>
            <div className={styles.bgColor}>
            </div>
            <div className={styles.content}>
              <Newsletter
                splash={this.props.splash}
                data={jsonData || data}/>
              <div className={styles.divider}>
                <Divider color={`#d3b05a`} dot />
              </div>
              <Social splash={this.props.splash} />
              <Links
                data={jsonData || data}/>
            </div>
          </div>
        )}
      />
    )
  }
}

export default Footer;
