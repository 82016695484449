// @flow

import { observable, action, computed } from 'mobx';
import reduce from 'lodash/reduce';

import UiStore from './ui-store';

type Slide = {
  large: {
    file: {
      url: string,
      details: {
        image: {
          width: number,
          height: number,
          naturalWidth?: number
        }
      }
    }
  },
  small?: {
    file: {
      url: string,
    }
  }
}

class OverlayStore {

  uiStore: UiStore;

  constructor(uiStore: UiStore) {
    this.uiStore = uiStore;
  }

  @observable posterActive: boolean = false;
  @observable posterUrl: string = '';

  @action
  setPosterActive = (isActive: boolean): void => {
    this.posterActive = isActive;
    isActive ? this.uiStore.initFullscreenOverlay() : this.uiStore.closeFullscreenOverlay();
  };

  @action
  setPosterUrl = (url: string): void => {
    this.posterUrl = url;
  };

  @observable overlayActive: boolean = false;
  @observable bgActive: boolean = false;

  timeout: any;

  @action
  setOverlayActive = (isActive: boolean): void => {
    if (this.timeout) clearTimeout(this.timeout);
    this.overlayActive = isActive;
    isActive ? this.uiStore.initFullscreenOverlay() : this.uiStore.closeFullscreenOverlay();
    this.timeout = setTimeout(() => {this.setBgActive(isActive)}, isActive? 10 : 200);
  };

  @action
  setBgActive = (isActive: boolean): void => {
    this.bgActive = isActive;
  };

  @observable singleScrollImage: boolean = false;

  @action
  setSingleScrollImage = (isImage: boolean): void => {
    this.singleScrollImage = isImage;
  };

  @observable useImage: boolean = false;

  @action
  setUseImage = (useImage: boolean): void => {
    this.useImage = useImage;
  };

  @observable content: any[] = [];
  @observable currentIndex: number = 0;
  @observable currentWidth: number = 0;
  @observable singleSlide: boolean = false;
  @observable activeContainerIndex: number = 0;

  @action
  setContent = (content: Slide[], currentIndex?: number=0): void => {
    this.singleSlide = content.length === 1;
    this.currentIndex = currentIndex || 0;
    this.content = content;
    const item = this.content[currentIndex].large || this.content[currentIndex].src;
    this.currentWidth = item.file.details.image.naturalWidth || 0;
    this.slideContent = [
      this.content[this.normalizeIndex(currentIndex - 1, this.content.length)],
      this.content[currentIndex],
      this.content[this.normalizeIndex(currentIndex + 1, this.content.length)],
    ];
    this.slidePositionX = ['-200vw', '0', '200vw'];
    this.slideOpacity = [0, 1, 0];
    this.activeContainerIndex = 1;
  };

  @computed
  get imagePercentWidths(): number[] {
    return reduce(this.slideContent, (acc: any, slide: Slide) => {
      const item = slide.large || slide.src;
      if (item) {
        const height: number = this.containerWidth * (item.file.details.image.height / item.file.details.image.width);
        if (height >= this.containerHeight) {
          const ratio: number = this.containerHeight / height;
          acc.push(ratio * 100);
        } else acc.push(100);
      } else acc.push(0);
      return acc;
    }, [])
  }

  @computed
  get imageRatios(): number[] {
    return reduce(this.slideContent, (acc: any, slide: Slide) => {
      const item = slide.large || slide.src;
      if (item) {
        acc.push(item.file.details.image.height / item.file.details.image.width * 100)
      } else acc.push(0);
      return acc;
    }, [])
  }

  @action shiftLeft = (): void => {
    let index: number = this.normalizeIndex(this.currentIndex - 1);
    this.slidePositionX = [
      this.slidePositionX[1],
      this.slidePositionX[2],
      this.slidePositionX[0]
    ];
    this.slideOpacity = [1, 1, 1];
    this.slidePositionX.forEach((x: string, innerIndex: number) => {
      if (x === '0') {
        this.activeContainerIndex = innerIndex;
      }
      if (x === '-200vw') {
        this.slideOpacity[innerIndex] = 0;
        this.slideContent[innerIndex] = this.content[this.normalizeIndex(index - 1)];
      }
    });
    this.currentIndex = index;
  };

  @action shiftRight = (): void => {
    let index: number = this.normalizeIndex(this.currentIndex + 1);
    this.slidePositionX = [
      this.slidePositionX[2],
      this.slidePositionX[0],
      this.slidePositionX[1]
    ];
    this.slideOpacity = [1, 1, 1];
    this.slidePositionX.forEach((x: string, innerIndex: number) => {
      if (x === '0') {
        this.activeContainerIndex = innerIndex;
      }
      if (x === '200vw') {
        this.slideOpacity[innerIndex] = 0;
        this.slideContent[innerIndex] = this.content[this.normalizeIndex(index + 1)];
      }
    });
    this.currentIndex = index;
  };

  normalizeIndex(index: number, length?: number): number {
    if (!length) length = this.content.length;
    if (index < 0) return length - 1;
    if (index > length - 1) return 0;
    return index;
  }

  @computed
  get contentLength(): number {
    return this.content.length;
  }

  @computed
  get margin(): number {
    return this.uiStore.windowWidth > 600 ? 100: 72;
  }

  @computed
  get containerHeight(): number {
    return this.uiStore.windowHeight - (this.margin * 2);
  }

  @computed
  get containerWidth(): number {
    return this.uiStore.windowWidth - (this.singleSlide && this.uiStore.windowWidth <= 600 ? 0 : (this.margin * 2));
  }

  @observable slideContent: any[] = [{}, {}, {}];
  @observable slideOpacity: number[] = [1, 1, 1];
  @observable slidePositionX: string[] = ['', '', ''];
}

export default OverlayStore;
