// @flow

import React from 'react';
import {inject, observer} from 'mobx-react';

import {STORE_OVERLAY} from '../../constants/stores';
import styles from './style.module.scss';
import OverlayStore from "../../stores/overlay-store";
import ReactResizeDetector from "react-resize-detector";

type LightboxImageProps = {
  overlay: OverlayStore,
  url: string,
}

@inject(STORE_OVERLAY)
@observer
class LightboxImage extends React.Component<LightboxImageProps, any> {

  height: number = 0;
  width: number = 0;
  naturalWidth: number = 0;

  handleResize = (width: number, height: number) => {
    if (height !== 0) this.height = height;
    if (width !== 0) this.width = width;
  };

  clicked = (el: any) => {
    this.props.overlay.setSingleScrollImage(true);
    this.props.overlay.setOverlayActive(true);
    this.props.overlay.setContent([{
      large: {
        file: {
          url: this.props.url,
          details: {
            image: {
              width: this.width,
              height: this.height,
              naturalWidth: el.naturalWidth
            }
          }
        }
      }
    }]);
  };

  loaded = (el: any) => {
    this.naturalWidth = el.naturalWidth;
  };

  render() {

    return (
      <div className={styles.container}>
        <img src={this.props.url}
             onClick={(e) => this.clicked(e.target)}
             onLoad={(e) => this.loaded(e.target)} />
        <ReactResizeDetector handleHeight handleWidth onResize={this.handleResize} />
        <div className={styles.mag}>
          <svg version="1.1"
               x="0px"
               y="0px"
               viewBox="0 0 60 60">
            <path d="M59.272,55.735L40.103,36.47C43.16,32.63,45,27.777,45,22.5 C45,10.093,34.907,0,22.5,0S0,10.093,0,22.5C0,34.905,10.093,45,22.5,45c5.328,0,10.223-1.873,14.082-4.979l19.146,19.243 c0.972,0.979,2.552,0.983,3.536,0.007C60.242,58.297,60.244,56.713,59.272,55.735z M22.5,40C12.85,40,5,32.148,5,22.5 C5,12.849,12.85,5,22.5,5C32.148,5,40,12.849,40,22.5C40,32.148,32.148,40,22.5,40z M32.5,20H25v-7.497 C25,11.12,23.936,10,22.554,10s-2.5,1.118-2.5,2.498L20.029,20H12.5c-1.382,0-2.5,1.118-2.5,2.5c0,1.38,1.118,2.5,2.5,2.5h7.524 L20,32.497c-0.003,1.38,1.115,2.5,2.495,2.503h0.002c1.38,0,2.5-1.118,2.503-2.497V25h7.5c1.38,0,2.5-1.12,2.5-2.5 C35,21.118,33.88,20,32.5,20z"/>
          </svg>
        </div>
      </div>
    )
  }
}

export default LightboxImage;
