// @flow

import { Link } from 'gatsby';
import React from 'react';
import { observer } from 'mobx-react';

import styles from './style.module.scss';

type LinksProps = {
  data: any,
}

@observer
class Links extends React.Component<LinksProps, any> {

  render() {

    return (
      <div className={styles.container}>
        <div className={styles.links}>
          {
            this.props.data.ui.footerLinks.map((item, index) => {
              if (!item.url && !item.externalUrl) return (
                <div className={styles.link}
                  key={`${index}FooterLink`}>
                  {item.display}
                </div>
              );
              return (
                <React.Fragment
                  key={`${index}FooterLink`}>
                  <div className={styles.divider}>

                  </div>
                  <div className={styles.link}>
                    {item.url && <Link to={item.url}>
                      {item.display}
                    </Link>}
                    {item.externalUrl && <a href={item.externalUrl} target={`_blank`} rel={`nofollow noreferrer`}>
                      {item.display}
                    </a>}
                  </div>
                </React.Fragment>
              )
            })
          }
        </div>
        <div className={styles.produced}>
          {this.props.data.ui.footerDisclaimer}
        </div>
      </div>
    )
  }
}

export default Links;
