// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-splash-js": () => import("./../../../src/pages/splash.js" /* webpackChunkName: "component---src-pages-splash-js" */),
  "component---src-pages-spotlight-js": () => import("./../../../src/pages/spotlight.js" /* webpackChunkName: "component---src-pages-spotlight-js" */),
  "component---src-pages-updates-js": () => import("./../../../src/pages/updates.js" /* webpackChunkName: "component---src-pages-updates-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-updates-js": () => import("./../../../src/templates/updates.js" /* webpackChunkName: "component---src-templates-updates-js" */)
}

