// @flow

import includes from 'lodash/includes';
import map from 'lodash/map';
import clone from 'lodash/clone';
import * as marked from 'marked';

let renderer = new marked.Renderer();
let parsedArray: any[] = [];
let list: string[] = [];
let skip: boolean = false;
let table: {
  header: any[],
  body: any[][],
  arrow: boolean,
} = {
  header: [],
  body: [],
  arrow: false,
};
let tableCount: number = 0;
let tableCols: number = 0;

renderer.heading = function (text, level) {
  parsedArray.push({
    type: `h${level}`,
    display: text
  });
  return '';
};

renderer.hr = function () {
  parsedArray.push({
    type: `divider`
  });
  return '';
};

renderer.list = function(body: string) {
  if (body) {
    parsedArray.push({
      type: 'ul',
      content: map(list, clone)
    });
  }
  list = [];
  return '';
};

renderer.table = function() {
  parsedArray.push({
    type: 'table',
    table: table,
  });
  tableCols = 0;
  tableCount = 0;
  table = {
    header: [],
    body: [],
    arrow: false,
  };
  return '';
};

renderer.tablecell = function(body: string, flags: any) {
  if (flags.align === 'right') table.arrow = true;
  if (flags.header) {
    tableCols++;
    table.header.push(body);
  } else {
    if (tableCount % tableCols === 0) {
      table.body.push([]);
    }
    tableCount++;
    table.body[table.body.length - 1].push(body);
  }
  return '';
};

renderer.listitem = function(body: string) {
  list.push(body);
  return '1';
};

renderer.image = function (href: string, title: string) {
  if (includes(['lightbox'], title)) {
    parsedArray.push({
      type: 'lightbox',
      url: !includes(href, 'http') ? href.replace('//', 'https://') : href
    });
    skip = true;
    return '';
  }
  if (!includes(href, 'http')) {
    return `<img src="${href.replace('//', 'https://')}">`;
  }
  return `<img src="${href}">`;
};

renderer.link = function (href: string, title: string, text: string) {
  const isExternal = includes(href, '//');
  if (includes(['blue', 'beige', 'patch-small', 'patch-large', 'sold-out', 'pink', 'pink-large'], title)) {
    const soldOut: boolean = title === 'sold-out';
    parsedArray.push({
      type: 'button',
      url: isExternal ? '' : href,
      urlExternal: isExternal ? href : '',
      buttonType: soldOut ? 'patch-large' : title,
      soldOut: soldOut,
      display: text
    });
    skip = true;
    return '';
  }
  if (isExternal) {
    return `<a href="${href}" target="_blank">${text}</a>`;
  }
  return `<a href="${href}">${text}</a>`;
};

renderer.code = function(text) {
  parsedArray.push({
    type: 'div',
    display: text
  });
  return '';
};

renderer.html = function(text) {
  parsedArray.push({
    type: 'div',
    display: text
  });
  return '';
};

renderer.paragraph = function(text) {
  if (skip) {
    skip = false;
    return '';
  }
  if (includes(text, 'youtube.com/embed')) {
    let config: any = {
      type: 'youtube',
      url: text.split('"')[1]
    };
    parsedArray.push(config);
  } else {
    parsedArray.push({
      type: 'p',
      display: text
    });
  }
  return '';
};

marked.setOptions({
  renderer: renderer,
  gfm: true,
  tables: true,
  breaks: true,
  pedantic: false,
  sanitize: false,
  smartLists: true,
  smartypants: false
});

export default function parseMarkdown(str: string): {
  type: 'youtube' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'div' | 'img' | 'ul' | 'button' | 'table' | 'lightbox',
  urlExternal?: string,
  url?: string,
  soldOut?: boolean,
  buttonType?: string,
  display?: string,
  content?: string[],
  table?: {
    header: any[],
    body: any[],
    arrow: boolean,
  }
}[] {
  parsedArray = [];
  if (str) marked(str);
  return map(parsedArray, clone);
}
