// @flow

import React from 'react';
import { observer } from 'mobx-react';

import styles from './style.module.scss';
import Button from '../../Button';

type NewsletterProps = {
  data: any,
  splash?: boolean,
}

@observer
class Newsletter extends React.Component<NewsletterProps, any> {

  render() {

    return (
      <>
        <div className={styles.button}>
          <Button type={this.props.splash? 'splash' : 'pink-alt'}
                  display={this.props.data.ui.footerButton.display}
                  url={this.props.data.ui.footerButton.url}/>
        </div>
      </>
    )
  }
}

export default Newsletter;
