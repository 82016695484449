// @flow

import { Link } from 'gatsby';
import React from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import * as classNames from 'classnames';

import styles from './style.module.scss';

type NavDropdownProps = {
  data: any,
  isDay: boolean,
  isHome: boolean,
}

@observer
class NavDropdown extends React.Component<NavDropdownProps, any> {

  @observable active: boolean = false;

  @action
  setLoaded = (active: boolean): void => {
    this.active = active;
  };

  @observable parentActive: boolean = false;

  @action
  setParentActive = (active: boolean): void => {
    this.parentActive = active;
  };

  onClick = e => {
    e.stopPropagation();
    this.setLoaded(false);
  };

  render() {

    const isActive = ({ href, location: { pathname } }) => {
      if (pathname === '/') {
        return null;
      }
      return href.includes(pathname.replace(/\//gm, '')) ? { className: styles.active } : null
    };

    const ExactNavLink = props => (
      <Link getProps={isActive} {...props} />
    );

    const isActiveParent = ({ isCurrent, location: { pathname } }) => {
      if (isCurrent) return {
        className: classNames({
          [styles.active]: true,
          [styles.parentLink]: true,
        })
      };
      if (pathname === '/') {
        return null;
      }
      if (this.props.data.childPages && this.props.data.childPages.reduce((isActive, { url }) => {
        if (url && url.includes(pathname.replace(/\//gm, ''))) return true;
        return isActive;
      }, false)) return {
        className: classNames({
          [styles.active]: true,
          [styles.parentLink]: true,
        })
      };
      return null;
    };

    const ExactNavLinkParent = props => (
      <Link getProps={isActiveParent} {...props} />
    );

    return (
      <div className={classNames({
        [styles.link]: true,
        [styles.day]: this.props.isDay,
        [styles.pageDesign]: !this.props.isHome
      })} onMouseLeave={() => this.setLoaded(false)}>
        {this.props.data.url ? (
          <ExactNavLinkParent className={styles.parentLink} to={`/${this.props.data.url}`}
            onMouseEnter={() => this.setLoaded(true)}
            onClick={e => this.onClick(e)}>
            {this.props.data.display}
          </ExactNavLinkParent>
        ) : (
          <a href={this.props.data.externalUrl} className={styles.parentLink} target={'_blank'} rel="noopener noreferrer">
            {this.props.data.display}
          </a>
        )}
        <div className={classNames({
          [styles.wrapper]: true,
          [styles.active]: this.active,
        })} onClick={e => this.onClick(e)}>
          {this.props.data.childPages && this.props.data.childPages.map((innerItem, innerIndex) => {
            return (
              <React.Fragment key={`${innerIndex}NavDropdown`}>
                {innerItem.url ?
                  <ExactNavLink
                    to={`/${innerItem.url}`}>
                    {innerItem.display}
                  </ExactNavLink> :
                  <a
                    href={innerItem.externalUrl}
                    target={'_blank'}
                    rel="noopener noreferrer"
                  >
                    {innerItem.display}
                  </a>}
              </React.Fragment>
            )
          })}
        </div>
      </div>
    )
  }
}

export default NavDropdown;
