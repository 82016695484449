// @flow

export const JSON_HOME = `home`;
export const JSON_SPLASH = `splash`;
export const JSON_NAV = `nav`;
export const JSON_UI = `ui`;
export const JSON_UPDATES = `updatesPage`;
export const JSON_GALLERY = `gallery`;
export const JSON_SPOTLIGHT = `spotlight`;
export const JSON_LINEUP = `lineup`;
export const JSON_NEWSLETTER = `newsletter`;
