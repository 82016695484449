// @flow

import { observable, action} from 'mobx';
import reduce from 'lodash/reduce';
import isObject from 'lodash/isObject';

import {
  CONTENTFUL_AUTH_HEADER,
  CONTENTFUL_AUTH_HEADER_KEY,
  CONTENTFUL_API,
  CONTENTFUL_CONTENT_TYPE,
} from '../constants/contentful';

type ContentfulRequest = {
  id?: string,
  order?: string,
  contentType?: string,
  fieldEquality?: {
    [fieldID: string]: string | boolean,
  },
  refresh?: boolean,
  limit?: number,
  skip?: number,
}

const CONTENTFUL_KEYS: any = {
  contentType: 'content_type',
  field: 'fields.',
  skip: 'skip',
  fields: 'fields',
  items: 'items',
  order: 'order',
  includes: 'includes',
  limit: 'limit',
  asset: 'Asset',
  entry: 'Entry',
  link: 'Link',
  getLinkedItemsParam: 'include=10',
  getLinkedItemsParamNoInclude: 'include=1',
  sys: 'sys',
  id: 'id',
  type: 'type',
  linkType: 'linkType'
};

function getRequestUrl(config: ContentfulRequest): string {
  let params: string = `?${CONTENTFUL_KEYS.getLinkedItemsParam}&`;
  if (config.contentType) params = `${params}${CONTENTFUL_KEYS.contentType}=${config.contentType}&`;
  if (config.fieldEquality) params = reduce(config.fieldEquality, (acc: string, val: any, key: string) => {
    acc += `${CONTENTFUL_KEYS.field}${key}=${val}&`;
    return acc;
  }, params);
  if (config.id) params = `${params}${CONTENTFUL_KEYS.sys}.${CONTENTFUL_KEYS.id}=${config.id}&`;
  if (config.skip) params = `${params}${CONTENTFUL_KEYS.skip}=${config.skip}&`;
  if (config.limit) params = `${params}${CONTENTFUL_KEYS.limit}=${config.limit}&`;
  if (config.order) params = `${params}${CONTENTFUL_KEYS.order}=${config.order}`;
  return `${CONTENTFUL_API}${params}`;
}

let assets: any = {};
let links: any = {};

function createAssetObject(acc: any, asset: any): any {
  if (asset.sys && asset.sys.contentType && asset.sys.contentType.sys.id) {
    acc[asset[CONTENTFUL_KEYS.sys][CONTENTFUL_KEYS.id]] = Object.assign({}, asset[CONTENTFUL_KEYS.fields], {
      contentType: asset.sys.contentType.sys.id
    });
  } else acc[asset[CONTENTFUL_KEYS.sys][CONTENTFUL_KEYS.id]] = asset[CONTENTFUL_KEYS.fields];
  return acc;
}

function link(acc: any, item: any, key: any): any {
  if (Array.isArray(item)) {
    acc[key] = reduce(item, link, []);
  } else if (isObject(item)) {
    if (item[CONTENTFUL_KEYS.sys] && item[CONTENTFUL_KEYS.sys][CONTENTFUL_KEYS.type] === CONTENTFUL_KEYS.link) {
      if (item[CONTENTFUL_KEYS.sys][CONTENTFUL_KEYS.linkType] === CONTENTFUL_KEYS.asset) {
        acc[key] = assets[item[CONTENTFUL_KEYS.sys][CONTENTFUL_KEYS.id]];
      } else if (item[CONTENTFUL_KEYS.sys][CONTENTFUL_KEYS.linkType] === CONTENTFUL_KEYS.entry) {
        acc[key] = reduce(links[item[CONTENTFUL_KEYS.sys][CONTENTFUL_KEYS.id]], link, {});
      } else acc[key] = item;
    } else acc[key] = item;
  } else acc[key] = item;
  return acc;
}

function parseContentfulResponse(resp: {
  includes?: any,
  items: any[]
}) {
  if (resp[CONTENTFUL_KEYS.items].length === 1) {
    let items: any = resp[CONTENTFUL_KEYS.items][0][CONTENTFUL_KEYS.fields];
    assets = {};
    links = {};
    if (resp[CONTENTFUL_KEYS.includes]) {
      if (resp[CONTENTFUL_KEYS.includes][CONTENTFUL_KEYS.asset]) {
        reduce(resp[CONTENTFUL_KEYS.includes][CONTENTFUL_KEYS.asset], createAssetObject, assets);
      }
      if (resp[CONTENTFUL_KEYS.includes][CONTENTFUL_KEYS.entry]) {
        reduce(resp[CONTENTFUL_KEYS.includes][CONTENTFUL_KEYS.entry], createAssetObject, links);
      }
      reduce(items, link, items);
    }
    return items;
  } else {
    return reduce(resp[CONTENTFUL_KEYS.items], (acc: any[], item: any) => {
      let items: any = item[CONTENTFUL_KEYS.fields];
      assets = {};
      links = {};
      if (resp[CONTENTFUL_KEYS.includes]) {
        if (resp[CONTENTFUL_KEYS.includes][CONTENTFUL_KEYS.asset]) {
          reduce(resp[CONTENTFUL_KEYS.includes][CONTENTFUL_KEYS.asset], createAssetObject, assets);
        }
        if (resp[CONTENTFUL_KEYS.includes][CONTENTFUL_KEYS.entry]) {
          reduce(resp[CONTENTFUL_KEYS.includes][CONTENTFUL_KEYS.entry], createAssetObject, links);
        }
        reduce(items, link, items);
      }
      acc.push(items);
      return acc;
    }, [])
  }
}

function parseArray(data: any) {
  return reduce(data, (acc: any, item: any) => {
    acc.edges.push({
      node: item
    });
    return acc;
  }, {
    edges: []
  })
}

class JsonStore {

  @observable store: Map<string, any> = new Map();

  @action
  setJson = (json: any, key: string): void => {
    this.store.set(key, json);
  };

  @action
  setJsonFromGraphQL = (data: any, key: string): void => {
    if (data) this.store.set(key, data);
  };

  getData = (type: string, query: ContentfulRequest, contentfulArray: boolean=false, key?: string): void => {
    fetch(getRequestUrl(query), {
      headers: {
        [CONTENTFUL_CONTENT_TYPE[0]]: CONTENTFUL_CONTENT_TYPE[1],
        [CONTENTFUL_AUTH_HEADER_KEY]: CONTENTFUL_AUTH_HEADER
      },
    })
      .then((response: any) => {
        return response.json();
      })
      .then((data: any) => {
        data = parseContentfulResponse(data);
        this.setJson({
          __ufcfUpdated: true,
          [type]: contentfulArray ? parseArray(data) : data,
        }, key || type);
      })
      .catch((e: any) => {
        console.error(e);
      })
  };
}

export default JsonStore;