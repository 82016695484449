const keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
  e = e || window.event;
  if (e.preventDefault)
    e.preventDefault();
  e.returnValue = false;
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

export function disableScroll() {
  if (window.addEventListener) // older FF
    window.addEventListener('DOMMouseScroll', preventDefault, false);
  window.onwheel = preventDefault; // modern standard
  window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
  window.ontouchmove  = preventDefault; // mobile
  document.onkeydown  = preventDefaultForScrollKeys;
}

export function enableScroll() {
  if (window.removeEventListener)
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
  window.onmousewheel = document.onmousewheel = null;
  window.onwheel = null;
  window.ontouchmove = null;
  document.onkeydown = null;
}

export function toggleLock(lock) {
  try {
    document.body.style.overflowY = lock ? 'hidden' : '';
    document.body.style.height = lock ? '100%' : '';
    document.body.style.width = lock ? '100%' : '';
    document.body.style.position = lock ? 'fixed' : '';
    document.documentElement.style.overflowY = lock ? 'hidden' : '';
    document.documentElement.style.height = lock ? '100%' : '';
    document.documentElement.style.position = lock ? 'fixed' : '';
    document.documentElement.style.width = lock ? '100%' : '';
  } catch(e) {

  }
}
