// @flow

import { observable, action, computed } from 'mobx';
import { TweenLite, ScrollToPlugin } from 'gsap/all';
// import throttle from 'lodash/throttle';

import { toggleLock } from '../utils/lock-scroll';

let initCount: number = 0;

class UiStore {

  plugin: ScrollToPlugin;

  constructor() {
    this.plugin = ScrollToPlugin;
  }

  initForBrowserUse(): void {

    let y: number = 0;
    let ticking: boolean = false;

    window.addEventListener('scroll', () => {
      y = window.scrollY || window.pageYOffset;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (initCount < 5 && this.windowHeight > 0) {
            this.setInitialScrolled(y);
            initCount++;
          }
          this.setSticky(y > 80);
          this.setScrollPos(y);
          ticking = false;
        });
        ticking = true;
      }
    });

    // window.addEventListener('scroll', throttle(() => {
    //   const y: number = window.scrollY || window.pageYOffset;
    //   if (initCount < 5 && this.windowHeight > 0) {
    //     this.setInitialScrolled(y);
    //     initCount++;
    //   }
    //   console.log(y);
    //   this.setSticky(y > 100 && this.windowScrollY > y);
    //   this.setScrollPos(y);
    //
    // }, 60));
  }

  @observable scrollingUp: boolean = false;

  @action
  setScrollingUp = (scrollingUp: boolean): void => {
    this.scrollingUp = scrollingUp;
  };

  @observable isDay: boolean = true;

  @action
  setIsDay = (isDay: boolean): void => {
    this.isDay = isDay;
  };

  @observable isDayRendered: boolean = true;

  @action
  setIsDayRendered = (rendered: boolean): void => {
    this.isDayRendered = rendered;
  };

  @observable isNightRendered: boolean = true;

  @action
  setIsNightRendered = (rendered: boolean): void => {
    this.isNightRendered = rendered;
  };

  smoothScrollTo(y: number, timeSeconds: number=0.3): void {
    TweenLite.to(window, timeSeconds, {scrollTo:y});
  }

  @observable initialScrolled: boolean = false;

  @action
  setInitialScrolled = (y: number): void => {
    this.initialScrolled =  y >= this.windowHeight;
  };

  @observable snackbarShowing: boolean = false;

  @action
  setSnackbarShowing = (showing: boolean): void => {
    this.snackbarShowing = showing;
  };

  @observable snackbarText: string = '';

  @action
  setSnackbarText = (text: string): void => {
    this.snackbarText = text;
  };

  @observable windowWidth: number = 0;
  @observable windowHeight: number = 0;

  @action
  setWindowWidth = (x: number): void => {
    this.windowWidth = x;
  };

  @action
  setWindowHeight = (y: number): void => {
    this.windowHeight = y;
  };

  initFullscreenOverlay(): void {
    const body: any = document.getElementById('body');
    this.cachedScrollY = this.windowScrollY;
    toggleLock(true);
    body.style.transform = `translateY(-${this.windowScrollY}px)`;
  }

  closeFullscreenOverlay(): void {
    const body: any = document.getElementById('body');
    toggleLock(false);
    body.style.transform = '';
    if (this.cachedScrollY !== 0) {
      setTimeout(() => {
        window.scrollTo(0, this.cachedScrollY);
        this.cachedScrollY = 0;
      }, 1);
    }
  }

  @observable windowScrollY: number = 0;
  cachedScrollY: number = 0;

  @computed get isScrolled(): boolean {
    return this.windowScrollY > 0;
  }

  @action
  setScrollPos = (y: number): void => {
    this.windowScrollY = y;
  };

  @observable navSticky: boolean = false;

  @action
  setSticky(sticky: boolean) {
    this.navSticky = sticky;
  }

  @observable navExpanded: boolean = false;

  @action
  setNavExpanded(expanded: boolean) {
    this.navExpanded = expanded;
  }

}

export default UiStore;
