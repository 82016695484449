// @flow

import { observable, action, computed } from 'mobx';

import UiStore from './ui-store';

class UpdatesStore {

  uiStore: UiStore;

  constructor(uiStore: UiStore) {
    this.uiStore = uiStore;
  }

  @observable containerWidth: number = 0;

  @action
  setContainerWidth = (x: number): void => {
    this.containerWidth = x + 24;
  };

  @computed get colNums(): number {
    if (this.uiStore.windowWidth > 900) return 2;
    return 1;
  }

  @observable numSlides: number = 0;

  @action
  setNumSlides = (num: number): void => {
    this.numSlides = num;
  };

  @computed get shifts(): number[] {
    const arr: number[] = [];
    const count = Math.ceil(this.numSlides / this.colNums);
    for (let i = 0; i < count; ++i) {
      arr.push(0);
    }
    return arr;
  }

  @observable currentIndex: number = 0;

  @action
  setIndex = (index: number): void => {
    if (index < 0) index = 0;
    if (index >= this.shifts.length) index = this.shifts.length - 1;
    this.currentIndex = index;
  };

  @computed get maxPosition(): number {
    const maxMoves = this.shifts.length - 1;
    let maxDistance: number = maxMoves * this.containerWidth;
    if (this.colNums === 3) {
      if (this.numSlides % 3 !== 0) {
        maxDistance -= (this.containerWidth * (2 / 3));
      }
    } else if (this.colNums === 2) {
      if (this.numSlides % 2 !== 0) {
        maxDistance -= (this.containerWidth / 2);
      }
    }
    return maxDistance;
  }

  @computed get position(): number {
    let position: number = this.currentIndex * this.containerWidth;
    if (position > this.maxPosition) position = this.maxPosition;
    if (position < 0) position = 0;
    return position;
  }

  @action
  shift = (isRight: boolean): void => {
    this.setIndex(isRight ? this.currentIndex + 1 : this.currentIndex - 1);
  };
}

export default UpdatesStore;